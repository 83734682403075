<template>
    <div class="timers" :class="[$mq]" v-if="show_timers">
        <Topbar :categories="categories" @selectCategory="selectCategory" @addTimer="show_timers = false" @showOptions="openModalChangeView()"></Topbar>

        <div id="board">
            <Column v-for="column in columns" :countColumns="columns.length" :visibilityType="currentVisibility" :key="column.id" :data="column" :timers="columnTimers[column.id] ? columnTimers[column.id] : []" @clickTimer="showPopupOptionsTimer" @longPress="showAlertLongTimer" :clickEnabledMulti="clickEnabledMulti" @addTimerSelected="addTimerSelected" @selectAllTimers="handleSelectAllTimers" @deleteSelectAll="handleDeleteSelectAll"></Column>
        </div>

        <div class="popup-container blocking popup-small" v-if="isPopupOpen">
            <div class="popup">
                <div class="list" :class="{ portrait: isApp || ['portrait'].includes($mq) }">
                    <div class="title-popup-change-view">{{ $t('label.popup.change_view') }} <span class="close-popup" @click="closePopup()"></span></div>
                    <div class="items-list" :class="{ 'mobile-landscape': isApp || ['portrait'].includes($mq) }">
                        <div class="item-list" v-for="option in popupOptions" @click="changeVisibility(option.value)" :class="[{ selected: currentVisibility === option.value }, { 'non-scroll': popupOptions.length < 6 }]">
                            <span class="selector-color" :style="{ background: currentVisibility === option.value ? '#000' : 'transparent' }" :class="[{ 'no-color': currentVisibility !== option.value }]"></span>
                            <span class="selector-item">{{ option.displayText }}</span>
                        </div>
                    </div>
                    <Button bType="change-view" :bLabel="$t('label.popup.change_view')" customClass="selector-popup" :bCallback="changeVisibilityEnd" />

                    <Button bType="cancel" customClass="selector-popup" :bCallback="closePopup" />
                </div>
            </div>
        </div>

        <div class="popup-container blocking popup-small" v-if="popupTimerOpen">
            <div class="popup">
                <div class="title-popup">{{ getTimers[keyTimer].data.product }} <span class="close-popup" @click="closePopupTimer()"></span></div>
                <div class="preview">
                    <Preview :refresh="false" :key="keyTimer" :calculate="n" :data="previewTimer"></Preview>
                    <div class="quantity">
                        <span>
                            Unidades: <span class="units"> {{ previewTimer.quantity }}</span>
                        </span>
                    </div>
                </div>
                <div class="list" :class="{ portrait: isApp || ['portrait'].includes($mq) }">
                    <Button bType="move" v-if="showMoveTimer" v-for="moveState in nextMoveTimer" :bLabel="$t('label.popup.move_timer', { s: moveState.name })" :customClass="['selector-popup', !canMoveTimer ? 'blocked' : '']" :bCallback="moveTimerNextState" :bCallbackProps="{ timer_id: keyTimer, next_state: moveState.id }" />
                    <Button v-if="statusPrinter && canPrintTimer" bType="print" :bLabel="$t('timersproduct.renew_printer')" customClass="selector-popup" :bCallback="printLabel" :bCallbackProps="keyTimer" />
                    <Button bType="delete" :bLabel="$t('label.popup.delete_timer')" customClass="selector-popup" :bCallback="deleteTimer" :bCallbackProps="keyTimer" />
                    <!-- <Button bType="cancel" :bLabel="$t('timersproduct.close_pre')" customClass="selector-popup" :bCallback="closePopupTimer" />-->
                </div>
            </div>
        </div>
    </div>
    <Products @timerCreated="timerCreated" v-else @closeProducts="show_timers = true" @createTimerInit="createTimerInit" @createTimerEnd="createTimerEnd"></Products>
</template>

<script>
import Column from '@/components/domain/timerproduct/column.vue'
import Topbar from '@/components/domain/timerproduct/topbar.vue'
import Products from '@/components/domain/timerproduct/products.vue'
import Button from '@/components/domain/ui/Button.vue'
import Preview from '@/components/domain/label/preview.vue'
import { TIMERPRODUCT_STATUS } from '@/constants'
import i18n from '@/i18n'
import { EventBus } from '@/eventBus.js'

export default {
    components: { Column, Topbar, Products, Button, Preview },
    name: 'TimerProduct',
    data() {
        return {
            timers: [],
            selectedCategory: false,
            show_timers: true,
            isPopupOpen: false,
            currentVisibility: 1,
            currentVisibilityBefore: 1,
            popupOptions: [
                {
                    displayText: this.$t('timersproduct.visibility.remaining_time'),
                    value: 1
                },
                {
                    displayText: this.$t('timersproduct.visibility.useful_life'),
                    value: 2
                }
            ],
            popupTimerOpen: false,
            previewTimer: {},
            keyTimer: 0,
            n: 0,
            printerConfigured: false,
            showMoveTimer: false,
            canMoveTimer: false,
            canPrintTimer: true,
            nextMoveTimer: false,
            clickEnabledMulti: false,
            lastTimerId: null,
            lastCallTime: 0,
            isTimersRestore: false
        }
    },
    computed: {
        columns() {
            return this.$store.getters['timerproduct/getStates']
        },
        categories() {
            return this.$store.getters['timerproduct/getCategories']
        },
        columnTimers: function () {
            return this.$store.getters['timerproduct/getTimersByCategory'](this.selectedCategory)
        },
        isApp() {
            return this.$store.getters.getIsApp
        },

        statusPrinter() {
            return this.$store.getters['printer/getStatusPrinter']
        },

        errorPrinter() {
            return this.$store.getters['printer/getStatusErrorPrinter']
        },

        getTimers() {
            return this.$store.getters['timerproduct/getTimers']
        }
    },
    methods: {
        openModalChangeView() {
            this.isPopupOpen = true
            this.currentVisibilityBefore = this.currentVisibility

            console.log(this.currentVisibilityBefore, 'HOMM')
        },
        showAlertLongTimer() {
            this.clickEnabledMulti = true
        },
        load() {
            let self = this

            this.$overlay.loading()

            // this.$store.dispatch('timerproduct/loadSchema').then(() => {
            this.$store.dispatch('timerproduct/loadTimers').then(() => {
                self.selectCategory(false)
                self.$overlay.hide()
            })
            // })

            if (!this.isApp) {
                this.checkPrinter(false, true)
            }
        },
        selectCategory(category) {
            console.log('selectCategory', category)
            var self = this
            self.$overlay.loading()
            this.$store.dispatch('timerproduct/selectCategory', category).then(() => {
                self.selectedCategory = category
                self.$overlay.hide()
            })
        },
        timerCreated() {
            this.show_timers = true
            this.load()
        },

        changeVisibility(visibility) {
            this.currentVisibility = visibility
            // this.isPopupOpen = false

            const option = this.popupOptions.find((opt) => opt.value === this.currentVisibility)

            EventBus.$emit('update-title', { mensaje: this.$t('page_title.' + this.$route.name) + ` (${option.displayText})` })
        },

        changeVisibilityEnd() {
            this.isPopupOpen = false

            const option = this.popupOptions.find((opt) => opt.value === this.currentVisibility)

            EventBus.$emit('update-title', { mensaje: this.$t('page_title.' + this.$route.name) + ` (${option.displayText})` })
        },

        closePopup() {
            this.isPopupOpen = false
            this.currentVisibility = this.currentVisibilityBefore
            const option = this.popupOptions.find((opt) => opt.value === this.currentVisibility)

            EventBus.$emit('update-title', { mensaje: this.$t('page_title.' + this.$route.name) + ` (${option.displayText})` })
        },

        showPopupOptionsTimer(id) {
            // console.log('showPopupOptionsTimer', id)
            const timers = this.$store.getters['timerproduct/getTimers']
            this.canPrintTimer = false
            this.canMoveTimer = false
            this.nextMoveTimer = false

            if (timers[id]) {
                const product = this.$store.getters['timerproduct/getProducts'](timers[id].reference_id)

                this.canPrintTimer = parseInt(product.states[timers[id].state].type) == 3
                // console.log('product', product)
                const formatId = product.states[timers[id].state].format
                // console.log('formatId', formatId)
                const format = this.$store.getters['printer/getTemplates'](formatId)
                // console.log('format', format)
                let data = { ...timers[id].data }
                var newPreviewStructure = format.realistic_preview ? JSON.parse(format.realistic_preview) : false
                let newPreviewData = {}
                var values = JSON.parse(format.data)
                for (var key in data) {
                    if (key.includes('caducidad', 'date', 'print_date', 'prep_date')) {
                        let aux = moment(data[key + '_timestamp'] * 1000)
                        data[key + '_day'] = aux.format('DD/MM/YYYY')
                        data[key + '_time'] = aux.format('HH:mm')
                        data[key + '_d1'] = aux.isoWeekday() == 1 ? 'x' : ' '
                        data[key + '_d2'] = aux.isoWeekday() == 2 ? 'x' : ' '
                        data[key + '_d3'] = aux.isoWeekday() == 3 ? 'x' : ' '
                        data[key + '_d4'] = aux.isoWeekday() == 4 ? 'x' : ' '
                        data[key + '_d5'] = aux.isoWeekday() == 5 ? 'x' : ' '
                        data[key + '_d6'] = aux.isoWeekday() == 6 ? 'x' : ' '
                        data[key + '_d7'] = aux.isoWeekday() == 7 ? 'x' : ' '
                    }
                }
                if (newPreviewStructure) {
                    // console.log('VALUES: ', values)
                    newPreviewStructure.fields.forEach((el) => {
                        let key = el.field

                        if (el.f_id && values[el.f_id]) {
                            const regex = /[\{|\[][a-zA-Z0-9_\}|\]]*/gi
                            var val = values[el.f_id]
                            // log('F_ID: ', el.f_id)
                            // log('Val: ', val)
                            var value = val

                            if (values[el.f_id].match(/[\{\}\[\]]/gi)) {
                                let m
                                var index = ''
                                while ((m = regex.exec(val)) !== null) {
                                    // This is necessary to avoid infinite loops with zero-width matches
                                    if (m.index === regex.lastIndex) {
                                        regex.lastIndex++
                                    }

                                    // The result can be accessed through the `m`-variable.
                                    m.forEach((match, groupIndex) => {
                                        index = match.replace(/[\{\}\[\]]/gi, '')
                                        // console.log('MATCH: ', match, index, data[index])
                                        value = value.replace(match, data[index])
                                        if (el.format && !moment(value, el.format, true).isValid()) {
                                            value = moment(value, 'DD/MM/YYYY HH:mm').format(el.format)
                                        }
                                    })
                                }
                                el.current = false
                            }

                            el.value = value
                        } else if (el.value && el.value.match(/[\{\}\[\]]/gi)) {
                            const regex = /[\{|\[][a-zA-Z0-9_\}|\]]*/gi
                            var val = el.value
                            var value = val
                            // console.log('VAL: ', val, 'VALUE:', value)
                            let m
                            var index = ''
                            while ((m = regex.exec(val)) !== null) {
                                // This is necessary to avoid infinite loops with zero-width matches
                                if (m.index === regex.lastIndex) {
                                    regex.lastIndex++
                                }

                                // The result can be accessed through the `m`-variable.
                                m.forEach((match, groupIndex) => {
                                    index = match.replace(/[\{\}\[\]]/gi, '')
                                    // console.log('MATCH: ', match, index, data[index])
                                    value = value.replace(match, data[index])

                                    if (el.format && !moment(value, el.format, true).isValid()) {
                                        value = moment(value, 'DD/MM/YYYY HH:mm').format(el.format)
                                    }
                                    // console.log('INDEX:', index, 'MATCH: ', match, 'VALUE:', value)
                                })
                            }
                            // console.log('BARCODE ', self.currentStep)
                            // console.log('BARCODE ', self.data[self.currentStep])

                            // var currentStep = self.data.filter((step) => {
                            //     // console.log(index, step)
                            //     return step.keyname == index
                            // })

                            // console.log(currentStep)

                            // self.previewPrint[currentRow].push({
                            //     v: value,
                            //     l: length,
                            //     current: index.split('_')[0] == self.data[self.currentStep].keyname.split('_')[0],
                            //     barcode: index.match(/_timestamp/gi) || (currentStep[0] && currentStep[0].type == 'barcode') ? true : false
                            // })
                            el.current = false
                            el.value = value
                        }
                    })
                    newPreviewData = Object.assign({}, newPreviewStructure)
                }

                this.previewTimer = newPreviewData
                this.previewTimer.quantity = data.quantity
                this.keyTimer = id
                this.n++
                let timer = timers[id]
                if (timer.status > TIMERPRODUCT_STATUS.EXPIRED) {
                    //get state where id is equal to timer.state from this.columns
                    let posState = 0
                    let currentStateOrder = -1
                    let currentStateId = -1
                    this.columns.forEach((state, index) => {
                        // console.log(state, index)
                        if (state.id == timer.state) {
                            // posState = index
                            currentStateOrder = state.order
                            currentStateId = state.id
                        } else if (state.id && state.channel) {
                            let idState = state.id.split('_')[0]
                            if (idState == timer.state && ((!timer.data.channel && state.channel == 1) || timer.data.channel == state.channel)) {
                                // posState = index
                                currentStateOrder = state.order
                                currentStateId = state.id
                            }
                        }
                    })
                    // let posState = this.columns.findIndex((state) => state.id == timer.state)
                    if (posState < this.columns.length - 2) {
                        let bFound = false
                        let nextState = false
                        let nexStateName = false
                        while (posState < this.columns.length - 1) {
                            // console.log('columnOrder',this.columns[posState].order,'posState', posState, 'currentStateOrder', currentStateOrder, 'currentStateId', currentStateId)
                            if (this.columns[posState].order >= currentStateOrder && this.columns[posState].id != currentStateId) {
                                nextState = this.columns[posState].id
                                // console.log(nextState)
                                if (nextState) {
                                    if (nextState.includes('channel')) {
                                        nextState = nextState.split('_')[0]
                                    }
                                    // console.log("Entro", this.columns[posState].id, nextState)
                                    nexStateName = this.columns[posState].name
                                    let bStateFound = typeof this.$store.getters['timerproduct/getProducts'](timer.reference_id).states[nextState] !== 'undefined'
                                    if (bStateFound) {
                                        bFound = bStateFound
                                        this.canMoveTimer = timer.status != TIMERPRODUCT_STATUS.PREPARATION
                                        if (!Array.isArray(this.nextMoveTimer)) {
                                            this.nextMoveTimer = []
                                        }
                                        this.nextMoveTimer.push({ name: nexStateName, id: this.columns[posState].id })
                                        this.showMoveTimer = true
                                    }
                                }
                            }
                            posState++
                        }
                        if (!bFound) {
                            //TODO
                            this.canMoveTimer = false
                            this.showMoveTimer = false
                        }
                    } else {
                        //TODO
                        this.canMoveTimer = false
                        this.showMoveTimer = false
                    }
                } else {
                    //TODO
                    this.canMoveTimer = false
                    this.canPrintTimer = false
                    this.showMoveTimer = false
                }
            }
            this.popupTimerOpen = true
        },

        closePopupTimer() {
            this.popupTimerOpen = false
        },

        moveTimerNextState(params) {
            let timerId = params.timer_id
            let nextStateId = params.next_state
            console.log('moveTimerNextState', timerId, nextStateId)
            const timers = this.$store.getters['timerproduct/getTimers']
            if (timers[timerId] && this.canMoveTimer) {
                let timer = timers[timerId]
                let currentState = this.columns.filter((state) => state.id == timer.state || (timer.data.channel && state.id == timer.state + '_channel' + timer.data.channel) || state.id == timer.state + '_channel1')[0]
                let nextState = this.columns.filter((state) => state.id == nextStateId || (timer.data.channel && state.id == nextStateId))[0]
                console.log('currentState', currentState)
                console.log('nextState', nextState)
                let bExistsRelId = false
                let bRemoveCurrentTimer = false
                if (timer.data.timer_rel_id || (currentState.channel && nextState.channel)) {
                    let timersColumn = this.columnTimers[nextStateId]
                    let found = timersColumn.find((t) => t.id == timer.data.timer_rel_id)
                    bExistsRelId = found
                    if (currentState.channel && nextState.channel /*&& currentState.channel > nextState.channel*/) {
                        //COMMENTED THIS CONDITION FOR RBI
                        bRemoveCurrentTimer = true
                    }
                }
                if (!bExistsRelId) {
                    console.log('timer', timer)

                    console.log(currentState)
                    if (currentState && currentState.channel) {
                        timer.data.timer_rel_id = timerId
                    }
                    timer.isLoading = true
                    if (timer.status > TIMERPRODUCT_STATUS.EXPIRED) {
                        //get state where id is equal to timer.state from this.columns
                        let posState = this.columns.findIndex((state) => state.id == timer.state)
                        console.log('posState', posState)
                        // if (posState < this.columns.length - 2) {
                        //     let bFound = false
                        //     let nextState = false
                        //     while (!bFound && posState < this.columns.length - 2) {
                        //         nextState = this.columns[posState + 1].id
                        //         bFound = typeof this.$store.getters['timerproduct/getProducts'](timer.reference_id).states[nextState] !== 'undefined'
                        //         posState++
                        //     }
                        //     if (bFound) {
                        let self = this
                        this.$store.dispatch('timerproduct/moveTimerNextState', { timer: timer, nextState: nextStateId }).then((response) => {
                            if (response) {
                                if (bRemoveCurrentTimer) {
                                    self.$store.dispatch('timerproduct/deleteTimer', { id: timerId }).then(() => {})
                                }
                                let msg = self.$tc('config.printer.success_save', parseInt(timer.data.quantity))
                                if (parseInt(timer.data.quantity) > 1) {
                                    msg = msg.replace('[num_copies]', timer.data.quantity)
                                }
                                msg = msg.replace('[product]', timer.data.product)
                                self.$snackbar.progress({
                                    closeable: true,
                                    message: msg
                                })
                            } else {
                                self.$snackbar.error({
                                    message: self.$t('config.printer.error_print_default'),
                                    closeable: true
                                })
                                console.log('posState3', posState)
                            }
                        })
                        //     } else {
                        //         //TODO
                        //         log('ESTE TIMER NO TIENE MAS ESTADOS')
                        //     }
                        // } else {
                        //     //TODO
                        //     log('Ya no se puede mover mas el timer')
                        // }
                    } else {
                        //TODO
                        log('Ya no se puede mover mas el timer')
                    }
                } else {
                    //TODO UNIFY
                }
            } else {
                this.$snackbar.error({
                    closeable: true,
                    message: this.$t('timerproduct.cannot_move')
                })
            }
            this.closePopupTimer()
        },

        deleteTimer(timerId) {
            var self = this
            const timers = this.$store.getters['timerproduct/getTimers']
            if (timers[timerId]) {
                let timer = timers[timerId]
                timer.isLoading = true
            }

            const timersSelect = []
            timersSelect.push(timerId)

            console.log(timersSelect)

            localStorage.setItem('deletedTimers', JSON.stringify(timersSelect))

            self.$store.dispatch('timerproduct/deleteTimerTemporarily', { timerId }).then(() => {
                self.$snackbar.error({
                    closeable: true,
                    message: self.$t('timers.remove_timers', { timers: 1 }),
                    customClass: 'delete-timer-button',
                    actionType: 'button',
                    textButton: 'Deshacer',
                    duration: 16000,
                    action: () => self.restoreTimers(),
                    onClose: () => self.handleDeleteTimer(timerId)
                })
                self.closePopupTimer()
            })
        },
        handleDeleteTimer(timerId) {
            if (!this.isTimersRestore) {
                var self = this
                this.$store.dispatch('timerproduct/deleteTimer', { id: timerId }).then(() => {
                    localStorage.removeItem('deletedTimers')
                    this.isTimersRestore = false
                    self.closePopupTimer()
                })
            }
        },
        checkPrinter(bValidateAndroid, bValidateWindows) {
            var bValidateAndroid = typeof bValidateAndroid !== 'undefined' ? bValidateAndroid : true
            var bValidateWindows = typeof bValidateWindows !== 'undefined' ? bValidateWindows : true

            var self = this
            var error = []

            var current_printer = this.$store.getters['printer/getCurrentPrinter']
            // console.log('checkPrinter, current_printer --> ', current_printer, this.$store.getters['printer/getPrinterConfig'](current_printer))
            var config = null
            if (!current_printer || !this.$store.getters['printer/getPrinterConfig'](current_printer)) {
                var printersConfig = this.$store.getters['printer/getPrinterConfig']()
                for (var p in printersConfig) {
                    if (config == null) {
                        var pc = printersConfig[p]
                        // if ((pc.platform == 'android' && this.$store.getters.getIsApp) || (pc.platform == 'windows' && !this.$store.getters.getIsApp)) {
                        if ((pc.platform == 'windows' && !this.$store.getters.getIsApp) || pc.mode == 'WIFI' || pc.print_mode == 'wifi' || (this.$store.getters.getIsApp && pc.platform == cordova.platformId)) {
                            config = Object.assign({}, pc)
                            this.$store.dispatch('printer/setCurrentPrinter', pc.id)
                        }
                    }
                }
            } else {
                config = Object.assign({}, this.$store.getters['printer/getPrinterConfig'](current_printer))
            }
            log('checkPrinter, config --> ', config)
            var bCanPrintWindows = false
            if (config) {
                var printer = this.$store.getters['printer/getModels'](config.model)
                log('checkPrinter, printer --> ', printer)
                if (printer) {
                    var templates = this.$store.getters['printer/getTemplatesByPrinterModel'](printer.id)
                    for (var k in templates) {
                        var label = self.$store.getters['printer/getLabels'](templates[k].label_id)
                        if (!bCanPrintWindows && typeof label.tpl_file_windows !== 'undefined' && label.tpl_file_windows != null) {
                            bCanPrintWindows = true
                        }
                    }
                    // console.log('checkPrinter', bCanPrintWindows)
                    if (self.$store.getters.getIsApp || bCanPrintWindows) {
                        // var printer = this.$store.getters['printer/getPrinter']
                        // console.log(JSON.parse(JSON.stringify(printer)))
                        // console.log(auxPrinter)
                        if ((typeof printer.name === 'undefined' || !printer.name) && (typeof printer.mode === 'undefined' || !printer.mode) && self.$store.getters.getIsApp) {
                            error.push(true)
                        } else if (!bCanPrintWindows && !self.$store.getters.getIsApp) {
                            error.push(true)
                        }
                        //TODO VALIDATE HOW DETECT IF ALL TEMPLATES ARE LOADED
                        // else if ((typeof auxPrinter.template_loaded === 'undefined' || !auxPrinter.template_loaded) && printer.templates[printer.config.template_id].model.sdk == 'brother' && process.env.VUE_APP_IS_APP == 'TRUE') {
                        //     error.push(true)
                        // }

                        if (error.indexOf(true) != -1) {
                            this.printerConfigured = false
                            // this.cantSelectCategoryPrinterError = true
                            // // console.log('sin configuracion')
                            // if (this.getRoleLoggedUser && this.getRoleLoggedUser.level > 1) {
                            //     self.$popup.alert({
                            //         closable: false,
                            //         blocking: false,
                            //         customClass: 'popup-label-admin',
                            //         message: self.$t('label.printer.alert_empty'),
                            //         textSave: self.$t('label.printer.alert_save'),
                            //         callSave: function (response) {
                            //             self.$router.push({
                            //                 name: 'Config',
                            //                 params: { page: 'printer' }
                            //             })
                            //         }
                            //     })
                            // } else {
                            //     self.$popup.alert({
                            //         blocking: false,
                            //         closable: false,
                            //         customClass: 'popup-label',
                            //         message: self.$t('label.printer.alert_role_level_1')
                            //     })
                            // }
                        } else {
                            // console.log('checkPrinter', 'All OK initStatusPrinter')
                            self.initStatusPrinter(bValidateAndroid, bValidateWindows)
                        }
                    }
                } else {
                    this.printerConfigured = false
                    // this.cantSelectCategoryPrinterError = true
                    // // console.log('sin configuracion')
                    // if (this.getRoleLoggedUser && this.getRoleLoggedUser.level > 1) {
                    //     self.$popup.alert({
                    //         closable: false,
                    //         blocking: false,
                    //         customClass: 'popup-label-admin',
                    //         message: self.$t('label.printer.alert_empty'),
                    //         textSave: self.$t('label.printer.alert_save'),
                    //         callSave: function (response) {
                    //             self.$router.push({
                    //                 name: 'Config',
                    //                 params: { page: 'printer' }
                    //             })
                    //         }
                    //     })
                    // } else {
                    //     self.$popup.alert({
                    //         blocking: false,
                    //         closable: false,
                    //         customClass: 'popup-label',
                    //         message: self.$t('label.printer.alert_role_level_1')
                    //     })
                    // }
                }
            } else {
                this.printerConfigured = false
                // this.cantSelectCategoryPrinterError = true
                // // console.log('sin configuracion')
                // if (this.getRoleLoggedUser && this.getRoleLoggedUser.level > 1) {
                //     self.$popup.alert({
                //         closable: false,
                //         blocking: false,
                //         customClass: 'popup-label-admin',
                //         message: self.$t('label.printer.alert_empty'),
                //         textSave: self.$t('label.printer.alert_save'),
                //         callSave: function (response) {
                //             self.$router.push({
                //                 name: 'Config',
                //                 params: { page: 'printer' }
                //             })
                //         }
                //     })
                // } else {
                //     self.$popup.alert({
                //         blocking: false,
                //         closable: false,
                //         customClass: 'popup-label',
                //         message: self.$t('label.printer.alert_role_level_1')
                //     })
                // }
            }
            // console.log('---------------')
        },
        validatePrinter() {
            if (this.printerError == i18n.t('config.printer.errors.bpac_no_installed')) {
                window.open('https://chrome.google.com/webstore/detail/ilpghlfadkjifilabejhhijpfphfcfhb', '_blank')
            }
            this.initStatusPrinter()
        },

        initStatusPrinter(bLaunchAndroidValidate, bLaunchWindowValidate) {
            var self = this
            var bLaunchAndroid = typeof bLaunchAndroidValidate !== 'undefined' ? bLaunchAndroidValidate : true
            var bLaunchWindow = typeof bLaunchWindowValidate !== 'undefined' ? bLaunchWindowValidate : true
            log('initStatusPrinter', bLaunchWindow)
            if (!this.checkingStatusPrinter) {
                if (this.$store.getters.getIsApp && bLaunchAndroid) {
                    // clearInterval(this.iIntervalStatusPrinter)
                    this.$store.dispatch('printer/statusPrinter')
                    var self = this
                    // this.iIntervalStatusPrinter = setInterval(function() {
                    //     self.$store.dispatch('printer/statusPrinter')
                    // }, 60000)
                } else if (!this.$store.getters.getIsApp && bLaunchWindow) {
                    if (!this.isValidatebPac) {
                        return true
                        return self.$store.dispatch('printer/validateBpac')
                    }
                }
            }
        },

        printLabel(timerId) {
            console.log('timerId', timerId)
            var self = this
            const timers = this.$store.getters['timerproduct/getTimers']
            if (timers[timerId]) {
                let timer = timers[timerId]
                let printData = []
                const product = this.$store.getters['timerproduct/getProducts'](timer.reference_id)
                console.log('product', product)
                const formatId = product.states[timer.state].format
                console.log('formatId', formatId)
                const format = this.$store.getters['printer/getTemplates'](formatId)
                var formatValues = format ? JSON.parse(format.data) : []
                console.log('formatValues', formatValues)
                var newPreviewStructure = format.realistic_preview ? JSON.parse(format.realistic_preview) : false
                console.log('newPreviewStructure', newPreviewStructure)

                let dates = ['print_date', 'date', 'prep_date', 'caducidad']

                for (var field in formatValues) {
                    var value = formatValues[field]

                    if (value) {
                        let aux = value

                        let start = aux.indexOf('[') > -1 ? aux.indexOf('[') : aux.indexOf('{')
                        start++
                        let end = aux.indexOf(']') > -1 ? aux.indexOf(']') : aux.indexOf('}')
                        let keyValue = aux.substring(start, end)
                        let oldKeyValue = aux.substring(start, end)

                        if (dates.includes(keyValue)) {
                            let aux = moment(timer.data[keyValue + '_timestamp'] * 1000)
                            timer.data[keyValue + '_d1'] = aux.isoWeekday() == 1 ? 'x' : ' '
                            timer.data[keyValue + '_d2'] = aux.isoWeekday() == 2 ? 'x' : ' '
                            timer.data[keyValue + '_d3'] = aux.isoWeekday() == 3 ? 'x' : ' '
                            timer.data[keyValue + '_d4'] = aux.isoWeekday() == 4 ? 'x' : ' '
                            timer.data[keyValue + '_d5'] = aux.isoWeekday() == 5 ? 'x' : ' '
                            timer.data[keyValue + '_d6'] = aux.isoWeekday() == 6 ? 'x' : ' '
                            timer.data[keyValue + '_d7'] = aux.isoWeekday() == 7 ? 'x' : ' '
                        }

                        while (keyValue) {
                            // console.log('KEYVALUE: ', keyValue)
                            keyValue = keyValue.replace('[', '').replace(']', '').replace('{', '').replace('}', '')
                            aux = aux.replace('[', '').replace(']', '').replace('{', '').replace('}', '')

                            // console.log(keyValue, aux, Object.assign({}, self.oParams), self.oParams[keyValue])
                            // if (!self.oParams[keyValue]) {
                            //     self.oParams[keyValue] = aux
                            // }
                            // console.log(keyValue, aux, Object.assign({}, self.oParams), self.oParams[keyValue])

                            // let newValue = self.oParams[keyValue] // cleanString(self.oParams[keyValue])
                            // if (newValue.indexOf(oldKeyValue) != -1) {
                            //     newValue = newValue.replace(oldKeyValue, '')
                            //     self.oParams[keyValue] = newValue
                            // }
                            aux = aux.replace(keyValue, timer.data[keyValue])

                            if (newPreviewStructure) {
                                newPreviewStructure.fields.forEach((el) => {
                                    if (el.f_id && el.f_id == field) {
                                        if (el.format) {
                                            aux = moment(aux, 'DD/MM/YYYY HH:mm').format(el.format)
                                        }
                                    } else if (el.value == value && el.format) {
                                        aux = moment(aux, 'DD/MM/YYYY HH:mm').format(el.format)
                                    }
                                })
                            }

                            if (['print_date', 'print_date_time', 'print_date_day'].includes(keyValue) && false) {
                                aux += ' *'
                            }

                            start = aux.indexOf('[') > -1 ? aux.indexOf('[') : aux.indexOf('{')
                            start++
                            end = aux.indexOf(']') > -1 ? aux.indexOf(']') : aux.indexOf('}')
                            keyValue = aux.substring(start, end)
                            oldKeyValue = aux.substring(start, end)
                        }
                        var data = aux // cleanString(aux)
                        data = data.trim() == '' ? ' ' : data
                        printData.push({ k: field, v: data })
                    } else {
                        printData.push({ k: field, v: ' ' })
                    }
                }

                console.log('printData', printData)
                var current_config = this.$store.getters['printer/getCurrentPrinter']
                var currentPrinterConfig = this.$store.getters['printer/getPrinterConfig'](current_config)
                self.$overlay.loading()
                this.$store
                    .dispatch('printer/printLabel', {
                        printData: printData,
                        num_copies: timer.data.quantity,
                        context: {
                            config: current_config,
                            printer: currentPrinterConfig.model,
                            template: format.id,
                            label: format.label_id
                        }
                    })
                    .then(function (response) {
                        if (self.statusPrinter) {
                            let msg = i18n.tc('config.printer.success_window_print', 1)
                            msg = msg.replace('[product]', product.name)
                            self.$snackbar.progress({
                                closeable: true,
                                message: msg
                            })
                            self.$overlay.hide()
                        } else {
                            let msg = !self.errorPrinter.status && self.errorPrinter.msg ? i18n.t(self.errorPrinter.msg) : i18n.tc('config.printer.error_print', 1)
                            msg.replace('[product]', product.name)
                            self.$snackbar.error({
                                closeable: true,
                                message: msg
                            })
                            self.$overlay.hide()
                        }
                    })
                    .catch((response) => {
                        let msg = !self.errorPrinter.status && self.errorPrinter.msg ? i18n.t(self.errorPrinter.msg) : i18n.tc('config.printer.error_print', 1)
                        msg = msg.replace('[product]', product.name)
                        self.$snackbar.error({
                            closeable: true,
                            message: msg
                        })
                        self.$overlay.hide()
                    })
            }
        },
        createTimerInit(product) {
            // var self = this
            // self.$store.dispatch('timerproduct/addTimerLocal', product)
            this.$overlay.loading()
        },
        createTimerEnd() {
            this.$overlay.hide()
        },
        addTimerSelected(info) {
            this.$store.commit('timerproduct/toggleTimerSelected', { timerId: info.timerId })

            this.verifyAllSelected(info.columnId)
        },
        verifyAllSelected(columnId) {
            const timerByColum = this.$store.getters['timerproduct/getTimersFilterByCategory'](columnId)
            const timerByColumSelected = this.$store.getters['timerproduct/getTimersFilterByCategorySelected'](columnId)

            if (timerByColum.length === timerByColumSelected.length) {
                const columnIndex = this.columns.findIndex((column) => column.id === columnId)
                if (columnIndex !== -1) {
                    this.columns[columnIndex].selectAll = true
                }
                console.log('HOMMMM OK IGUALES', this.columns, columnId, columnIndex, this.columns[columnIndex])
            } else {
                const columnIndex = this.columns.findIndex((column) => column.id === columnId)
                if (columnIndex !== -1) {
                    this.columns[columnIndex].selectAll = false
                }
            }
        },
        handleSelectAllTimers(data) {
            const timerByColum = this.$store.getters['timerproduct/getTimersFilterByCategory'](data.column.id)

            timerByColum.forEach((timer) => {
                if (data.deselect) {
                    this.$store.commit('timerproduct/toggleTimerSelected', { timerId: timer.id, forceFalse: true })
                    this.verifyAllSelected(data.column.id)
                } else {
                    this.$store.commit('timerproduct/toggleTimerSelected', { timerId: timer.id, forceTrue: true })
                    this.verifyAllSelected(data.column.id)
                }
            })
        },
        handleDeleteSelectAll() {
            const timers = this.$store.getters['timerproduct/getTimers']

            const timersSelect = Object.entries(timers).reduce((ids, [key, timer]) => {
                if (timer.isSelected) {
                    ids.push(timer.id)
                }
                return ids
            }, [])

            timersSelect.forEach((timerId) => {
                if (timers[timerId]) {
                    let timer = timers[timerId]
                    timer.isLoading = true
                }
            })

            console.log(timersSelect)

            localStorage.setItem('deletedTimers', JSON.stringify(timersSelect))

            const chunkedTimers = this.chunkArray(timersSelect, 20)
            var self = this

            const timersCount = timersSelect.length
            console.log(timersCount)

            const promises = chunkedTimers.map((chunkTimer) => {
                return self.$store.dispatch('timerproduct/deleteTimersTemporarily', { timers: chunkTimer })
            })

            Promise.all(promises).then(() => {
                self.$snackbar.error({
                    closeable: true,
                    message: self.$t('timers.remove_timers', { timers: timersCount }),
                    customClass: 'delete-timer-button',
                    actionType: 'button',
                    textButton: 'Deshacer',
                    duration: 16000,
                    action: () => self.restoreTimers(),
                    onClose: () => self.handleSnackbarClose()
                })
                self.closePopupTimer()
            })
        },
        async handleSnackbarClose() {
            if (!this.isTimersRestore) {
                const deletedTimers = JSON.parse(localStorage.getItem('deletedTimers'))
                if (deletedTimers && deletedTimers.length > 0) {
                    const chunkedDeleteTimers = this.chunkArray(deletedTimers, 20)
                    const deletePromises = chunkedDeleteTimers.map((chunk) => {
                        return this.$store.dispatch('timerproduct/deleteTimers', { timers: chunk })
                    })

                    await Promise.all(deletePromises)
                    localStorage.removeItem('deletedTimers')
                    this.isTimersRestore = false
                }
            }
        },
        restoreTimers: async function () {
            try {
                this.isTimersRestore = true
                const savedTimers = JSON.parse(localStorage.getItem('deletedTimers'))
                console.log('ESTEEEE', savedTimers)
                if (savedTimers && savedTimers.length > 0) {
                    const chunkedRestoreTimers = this.chunkArray(savedTimers, 20)

                    const restorePromises = chunkedRestoreTimers.map((chunk) => {
                        return this.$store.dispatch('timerproduct/restoreTimers', { timers: chunk })
                    })

                    await Promise.all(restorePromises)

                    this.$snackbar.close()
                    this.$snackbar.progress({
                        message: this.$t('timers.restore_success'),
                        closeable: true
                    })

                    localStorage.removeItem('deletedTimers')
                    this.$store.dispatch('timerproduct/loadTimers')
                    this.$snackbar.close()
                }
            } catch (error) {
                this.$snackbar.error({
                    message: this.$t('timers.restore_error'),
                    closeable: true
                })
            } finally {
                this.isTimersRestore = false
            }
        },
        chunkArray(array, chunkSize) {
            return Array.from({ length: Math.ceil(array.length / chunkSize) }, (_, i) => array.slice(i * chunkSize, (i + 1) * chunkSize))
        }
    },
    created() {
        this.load()
    },
    mounted() {
        var self = this
        const create = self.$route.query.create === 'true'
        if (create) {
            this.show_timers = false
        }

        const option = this.popupOptions.find((opt) => opt.value === this.currentVisibility)

        EventBus.$emit('update-title', { mensaje: this.$t('page_title.' + this.$route.name) + ` (${option.displayText})` })
    },
    watch: {
        getTimers: {
            handler(newTimers, oldTimers) {
                const hasSelectedTimer = Object.values(newTimers).some((timer) => timer.isSelected === true)
                if (!hasSelectedTimer) {
                    this.clickEnabledMulti = false
                }
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
.timers {
    .preview {
        margin: 0rem !important;
        margin-bottom: 0rem !important;
        max-height: 100%;

        .quantity {
            margin-top: 1rem;
            text-align: right;
            .units {
                font-weight: 700;
            }
        }
    }
    #board {
        padding: 8px;
        border-radius: 4px;
        width: 100%;
        height: calc(100% - 54px);
        display: flex;
        position: absolute;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        gap: 0;
        justify-content: space-evenly;
    }

    .popup-container {
        position: fixed;
    }

    .popup-container.popup-small .popup .title-popup {
        width: 104%;
        padding: 1rem;
        background-color: $color-primary-800;
        color: $color-white;
        font-family: $text-bold;
        height: auto;
        position: relative;
        top: -0.47rem;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        display: flex; /* para alinear el contenido */
        justify-content: space-between;
        align-items: center;
    }

    .popup-container.popup-small .popup .title-popup-change-view {
        width: 112%;
        padding: 1rem;
        background-color: $color-primary-800;
        color: $color-white;
        font-family: $text-bold;
        height: auto;
        position: relative;
        top: -1.5rem;
        left: -1.5rem;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        display: flex; /* para alinear el contenido */
        justify-content: space-between;
        align-items: center;
    }

    .popup-container.popup-small .popup .title-popup-change-view .close-popup {
        color: white;
        display: inline-block;
        width: 20px;
        height: 20px;
        text-align: center;
        border-radius: 50%;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        @include background($image: img('close_producttimer.svg'), $size: 14px, $position: left center);
    }

    @media (max-width: 900px) {
        .popup-container.popup-small .popup .title-popup-change-view {
            top: -1.5rem;
            left: -1.2rem;
        }
    }

    .popup-container.popup-small .popup .title-popup .close-popup {
        color: white;
        display: inline-block;
        width: 20px;
        height: 20px;
        text-align: center;
        border-radius: 50%;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        @include background($image: img('close_producttimer.svg'), $size: 14px, $position: left center);
    }

    .popup-container .items-list {
        padding-right: 0px !important;
        margin: 0px !important;
    }

    .popup-container.popup-small .popup {
        max-width: 450px;
    }

    .popup-container.blocking {
        z-index: 44;
    }

    .list {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
        padding: 16px;

        .content-button {
            padding: 0 !important;
        }
        .selector-cancel-btn {
            width: 100%;
            margin: 0px;
        }
        .item-list {
            padding: 8px;
            display: flex;
            align-items: center;
            gap: 12px;
            cursor: pointer;
            width: 98%;
            margin: 8px 0;
            border: 1px solid $color-neutral-300;
            @include border-radius(4px);
            transition: all 0.3s ease-in-out;

            &.non-scroll {
                width: 100%;
            }

            &:hover {
                background-color: $color-selector;
            }

            &.selected {
                background-color: $color-neutral-200;
            }

            &.clear-value {
                color: $color-letter-selector;
                width: 100%;
                padding-right: 8px;
                font-size: 1.1em;
                padding-left: 38px;
                padding-right: 8px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                overflow-wrap: anywhere;
            }
        }

        &.portrait {
            .clear-value {
                font-size: 0.8em;
            }
        }
        .items-list {
            margin-top: 2px;
            max-height: 280px;
            overflow-y: auto;
            padding-right: 8px;
            width: 100%;

            &.mobile-landscape {
                max-height: 205px;
                .selector-item {
                    font-size: 0.8em;
                    min-height: 1.2em;
                    color: $color-letter-selector;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    overflow-wrap: anywhere;
                }
            }
            .selector-color {
                padding: 5px;
                @include border-radius(100%);
                margin-left: 8px;

                &.no-color {
                    border: 1px solid $color-selector2;
                    position: relative;
                }
            }

            .selector-item {
                font-size: 1.1em;
                min-height: 1.2em;
                color: $color-letter-selector;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
</style>
